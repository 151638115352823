import React from "react";
import Web3 from "web3";
import axios from 'axios';
import { NODE_URL,BUSD_ADDRESS,NODE_EXPLORER,BUSD_ABI,CHAIN_ID } from './config/usdt';
import { NODE_ADDRESS,NODE_ABI } from './config/node';
import Web3Modal from "web3modal";
import { ToastContainer, toast } from 'react-toastify';
import SuccessImage from "./images/success.svg"
import ErrorImage from "./images/error.svg"
import 'react-toastify/dist/ReactToastify.css'
import api from "./config/api";

const Network = {
    bsc: {
        chainId: `0x${Number(CHAIN_ID).toString(16)}`,
        chainName: "Binance Smart Chain Mainnet",
        nativeCurrency: {
            name: "BNB",
            symbol: "BNB",
            decimals: 18
        },
        rpcUrls: [NODE_URL],
        blockExplorerUrls: [NODE_EXPLORER]
    },
}

const providerOptions = {
    walletconnect: {
        options: {
            rpc: {
                97: NODE_URL
            },
            chainId: CHAIN_ID
        }
    }
}

const web3Modal = new Web3Modal({
    network: "mainnet",
    providerOptions,
    cacheProvider: true,
    theme: "dark"
});

class Deposit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account: null,
            is_connected: false,
            is_loading:false,
            step:1,
            is_approve_usdt:0,
            item:props.item
        }
        this.provider=null;
        this.web3=null;
    }
    
    async componentDidMount() {
        let provider;
        if(web3Modal.cachedProvider){
            provider=await web3Modal.connect()
        }
        else{
            provider = await web3Modal.connect();
        }
        this.setState({ auth_token: localStorage.getItem('auth_token') });
        if (provider) {
            this.provider=provider;
            const web3 = new Web3(this.provider || Network.bsc.rpcUrls);
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                this.setState({ is_connected: true })
                this.setState({ account: accounts[0],step:2 })
                this.setState({ net: "BSC MAIN NET"});
                const TokenContract = new web3.eth.Contract(BUSD_ABI, BUSD_ADDRESS);
                const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                if (web3.utils.fromWei(is_approve, 'ether') >= 410) {
                    this.setState({ is_approve_usdt: 1,step:3 })
                }
            }
            const networkId = await web3.eth.net.getId()
            if (networkId !== CHAIN_ID) {
                await this.provider.request({
                    method: "wallet_addEthereumChain",
                    params: [
                        {
                            ...Network["bsc"]
                        }
                    ]
                });

                setTimeout(function(){
                    window.location.reload();
                },3000)
                return
            }
            
            await provider.on("accountsChanged", async accounts => {
                const web3 = new Web3(this.provider);
                this.web3=web3;
                if (accounts.length > 0) {
                    this.setState({ account: accounts[0] })
                    this.setState({ is_connected: true })
                }
                else {
                    this.setState({ is_connected: false })
                }
            });
        }
        else {

        }
    }

    async doConnect() {
        const provider = await web3Modal.connect();
        //const provider = await detectEthereumProvider();
        if (provider) {
            try {
                this.provider=provider;
                const web3 = new Web3(provider);
                this.web3=web3;
                let accounts = await web3.eth.getAccounts();
                if (accounts.length) {
                    this.setState({ account: accounts[0] })
                    this.setState({ is_connected: true,step:2 })
                }
            } catch (error) {
                if (error.code === 4001) {
                    alert(error.message);
                }
                else {
                    alert("Install Metamask or Other Wallet");
                }
            }
        } else {
            alert("Install Metamask or Other Wallet");
        }
    }

    /** 
     * Approve BUSD 
     */
    async approveBUSD() {
        if (this.state.is_connected) {
            this.setState({ onpress: 1 })
            let that = this;
            const web3 = new Web3(this.provider);
            const Token = new web3.eth.Contract(BUSD_ABI, BUSD_ADDRESS);
            
            const resolveAfter3Sec = new Promise((resolve, reject) => {
                Token.methods.approve(NODE_ADDRESS, web3.utils.toWei('10000000', 'ether')).send(
                {
                    from: this.state.account,
                })
                .on('transactionHash', function (hash) {
                })
                .on('receipt', function (receipt) {
                    //console.log("Receipt",receipt);
                    that.setState({ onpress: 0, is_approve_usdt: 1,step:3 })
                    resolve(true)
                })
                .on('confirmation', function (confirmationNumber, receipt) {
                })
                .on('error', function (error, receipt) {
                    toast.error(error.message);
                    that.setState({ onpress: 0,step:2 })
                    reject(error.message)
                });
            });
            toast.promise(
                resolveAfter3Sec,
                {
                    pending: "Processing...",
                    success: "Approved",
                    error: "Rejected",
                }, {
                position: "top-center",
                autoClose: !1,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,
            })
        }
        else {
            toast.error("Connect Wallet");
        }
    }

    /**
     * Buy Item
     */
    async buy() {
        if (this.state.is_connected) {
            this.setState({ onpress: 1 })
            let that = this;
            const web3 = new Web3(this.provider);
            const Staking = new web3.eth.Contract(NODE_ABI, NODE_ADDRESS);
            const resolveAfter3Sec = new Promise((resolve, reject) => {
                Staking.methods.buyItem(this.state.item).send(
                    {
                        from: this.state.account,
                    })
                    .on('transactionHash', function (hash) {
                    })
                    .on('receipt', function (receipt) {
                        console.log("Receipt",receipt);
                        that.setState({ onpress: 0,txnid:receipt.transactionHash,step:4,txtStatus:1 })
                        that.paymentStore(receipt.from);
                        resolve(true);
                    })
                    .on('confirmation', function (confirmationNumber, receipt) {
                    })
                    .on('error', function (error, receipt) {
                        console.log("Error",error);
                        toast.error(error.message);
                        that.setState({ onpress: 0,txtStatus:2,step:4, })
                        reject(error.message)
                    });
            });
            toast.promise(
                resolveAfter3Sec,
                {
                    pending: "Processing...",
                    success: "Purchased",
                    error: "Failed",
                }, {
                position: "top-center",
                autoClose: !1,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,
            }
            )
        }
        else {
            toast.error("Connect Wallet");
        }
    }

    /**
     * Store 
     * @param {string} wallet 
     */
    paymentStore(wallet) {
        axios({
            method: 'post', url: api.savePurchase,
            data: {
                wallet:wallet,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (ress) {
            console.log(ress)
        }).catch(function (e) {
            console.log(e)
        });
    }

    render() {
        return (
            <>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
                <div className="">
                    {
                        this.state.step==1?
                        <>
                            <button type="button" className="btn btn-primary" onClick={() => this.doConnect()}>{"Connect Wallet"}</button>
                        </>
                        :null
                    }
                    {
                        
                        this.state.step==2?
                            this.state.onpress ?
                                <div className="text-center">
                                    <button type="button" className="btn btn-primary">
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span className="">&nbsp;{"Processing..."}</span>
                                    </button> 
                                </div>:
                                <div className="text-center">
                                    <button type="button" className="btn btn-primary" onClick={() => this.approveBUSD()}>{"Approve Spending Limit"}</button>
                                </div>
                            :null
                    }
                    {
                        this.state.step==4?
                            <div className="text-center">
                                {
                                    this.state.txtStatus==1?<img src={SuccessImage} alt="" style={{height:200}}/>:null
                                }
                                {
                                    this.state.txtStatus==1?<h6>{"Completed"}</h6>:null
                                }

                                {
                                    this.state.txtStatus==2?<img src={ErrorImage} alt="" style={{height:200}}/>:null
                                }
                                {
                                    this.state.txtStatus==2?<h6>{"Failed"}</h6>:null
                                }
                                <b><a target={"_blank"} href={NODE_EXPLORER+"/tx/"+this.state.txnid}>{"View Transaction"}</a></b><br/>
                                <b><a  href="/user/account">Go To Dashboard</a></b>
                            </div>
                            :null
                    }
                    
                    {/****Button ******/}
                    {
                        this.state.step==3?
                            this.state.onpress ?
                                <div className="text-center">
                                    <button type="button" className="btn btn-primary">
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span className="">&nbsp;{"Processing..."}</span>
                                    </button> 
                                </div>:
                                <div className="text-center">
                                    <button type="button" className="btn btn-primary" onClick={() => this.buy()}>{"Confirm"}</button>
                                </div>
                            :null
                    }
                
                </div>
            </>
        );
    }
}
export default Deposit;
