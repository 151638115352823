import React from "react";
import Bind from "./Bind";
import PurchaseItem from "./PurchaseItem";
import ReactDOM from 'react-dom/client';

if (document.getElementById('purchase_item')) {
    const propsContainer = document.getElementById("purchase_item");
    const props = Object.assign({}, propsContainer.dataset);
    ReactDOM.createRoot(document.getElementById('purchase_item')).render(     
        <PurchaseItem {...props}/>
    );
}

if (document.getElementById('bind_wallet')) {
    const propsContainer = document.getElementById("bind_wallet");
    const props = Object.assign({}, propsContainer.dataset);
    ReactDOM.createRoot(document.getElementById('bind_wallet')).render(     
        <Bind {...props}/>
    );
}

