import React from "react";
import api from "./config/api.js";
import Web3 from 'web3'
import axios from "axios";
import Web3Modal from "web3modal";
import { NODE_URL,NODE_EXPLORER,CHAIN_ID } from './config/usdt';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Network = {
    bsc: {
        chainId: `0x${Number(CHAIN_ID).toString(16)}`,
        chainName: "Binance Smart Chain Mainnet",
        nativeCurrency: {
            name: "BNB",
            symbol: "BNB",
            decimals: 18
        },
        rpcUrls: [NODE_URL],
        blockExplorerUrls: [NODE_EXPLORER]
    },
}

const providerOptions = {
    walletconnect: {    
        options: {
            rpc: {
                97: NODE_URL
            },
            chainId: CHAIN_ID
        }
    }
}

const web3Modal = new Web3Modal({
    network: "mainnet",
    providerOptions,
    cacheProvider: true,
    theme: "dark"
});

class Bind extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account: null,
            is_connected: false,
            is_loading:false,
        }
        this.provider=null;
    }
    
    async componentDidMount() {
        let provider;
        if(web3Modal.cachedProvider){
            provider=await web3Modal.connect()
        }
        else{
            provider = await web3Modal.connect();
        }
        if (provider) {
            this.provider=provider;
            const web3 = new Web3(this.provider || Network.bsc.rpcUrls);
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                this.setState({ is_connected: true })
                this.setState({ account: accounts[0] })
                this.setState({ net: "BSC MAIN NET"});
            }
            const networkId = await web3.eth.net.getId()
            if (networkId !== CHAIN_ID) {
                await this.provider.request({
                    method: "wallet_addEthereumChain",
                    params: [
                        {
                            ...Network["bsc"]
                        }
                    ]
                });

                setTimeout(function(){
                    window.location.reload();
                },3000)
                return
            }
            
            await provider.on("accountsChanged", async accounts => {
                const web3 = new Web3(this.provider);
                this.web3=web3;
                if (accounts.length > 0) {
                    this.setState({ account: accounts[0] })
                    this.setState({ is_connected: true })
                }
                else {
                    this.setState({ is_connected: false })
                }
            });
        }
        else {

        }
    }

    async doConnect() {
        const provider = await web3Modal.connect();
        //const provider = await detectEthereumProvider();
        if (provider) {
            try {
                this.provider=provider;
                const web3 = new Web3(provider);
                this.web3=web3;
                let accounts = await web3.eth.getAccounts();
                if (accounts.length) {
                    this.setState({ account: accounts[0] })
                    this.setState({ is_connected: true })
                }
            } catch (error) {
                if (error.code === 4001) {
                    alert(error.message);
                }
                else {
                    alert("Install Metamask or Other Wallet");
                }
            }
        } else {
            alert("Install Metamask or Other Wallet");
        }
    }

    /**
     * Login
     */
    async doSave() {
        let that=this;
        this.setState({is_loading:true})
        await axios({
            method: 'post', url: api.saveWallet,
            data: {
                wallet: this.state.account,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (ress) {
            that.setState({is_loading:false})
            if(ress.data.error==200){
                toast.success('Bound Success');
            }
            else{
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            console.log(e);
            that.setState({is_loading:false})
        });
    }

    render() {
        return (
            <>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    rtl={false}
                    theme="dark"
                />
                <p className="text-danger text-start">
                    * Only 1 time wallet bind, after bound unable to change.<br/> 
                    * Make sure you  can choose correct wallet.
                </p>
                {
                    this.state.is_connected ?
                    <p className="text-warning text-center">Your Connected Wallet is <br/><b>{this.state.account}</b></p>
                    :
                    null
                }
                {
                    this.state.is_connected ?
                        this.state.is_loading?
                            <button type="button" className="btn btn-primary">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span className="">&nbsp;{"Processing..."}</span>
                            </button> 
                        :
                        <button className="btn btn-primary" onClick={()=>this.doSave()}>{"Bind Now"}</button> :
                        this.state.is_loading?
                            <button type="button" className="btn btn-primary">
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span className="">&nbsp;{"Processing..."}</span>
                                </button> 
                            :
                            <button className="btn btn-primary" onClick={() => this.doConnect()}>{"Connect Wallet"}</button>
                }
            </>
        );
    }
}
export default Bind;
